window.addEventListener("load", function () {
  function chooseCategory() {
    const categories = document.querySelectorAll(".job-list");
    const realEstateAgent = document.querySelector(".job-list-eiendomsmegler");
    const realEstateAgentQuotes = document.querySelector(".eiendomsmegler");
    const leader = document.querySelector(".job-list-ledelse");
    const leaderQuotes = document.querySelector(".ledelse");
    const newBuildings = document.querySelector(".job-list-nybygg");
    const newBuildingsQuotes = document.querySelector(".nybygg");
    const businessBrokerage = document.querySelector(
      ".job-list-naeringsmegler"
    );
    const businessBrokerageQuotes = document.querySelector(".naeringsmegler");

    if (realEstateAgent) {
      realEstateAgent.classList.add("active");

      if (realEstateAgent.classList.contains("active")) {
        realEstateAgentQuotes.classList.add("show");
      } else {
        realEstateAgentQuotes.classList.remove("show");
      }
    }

    categories.forEach((element) =>
      element.addEventListener("click", (event) => {
        categories.forEach((element) => {
          element.classList.remove("active");
        });
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        } else {
          element.classList.add("active");
        }

        if (realEstateAgent.classList.contains("active")) {
          realEstateAgentQuotes.classList.add("show");
        } else {
          realEstateAgentQuotes.classList.remove("show");
        }

        if (leader.classList.contains("active")) {
          leaderQuotes.classList.add("show");
        } else {
          leaderQuotes.classList.remove("show");
        }

        if (newBuildings.classList.contains("active")) {
          newBuildingsQuotes.classList.add("show");
        } else {
          newBuildingsQuotes.classList.remove("show");
        }

        if (businessBrokerage.classList.contains("active")) {
          businessBrokerageQuotes.classList.add("show");
        } else {
          businessBrokerageQuotes.classList.remove("show");
        }
      })
    );
  }
  chooseCategory();
});
